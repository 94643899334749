import React from 'react';
// import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import Views from '../Views';
import imgHorz1 from '../../img/galleryImgHorz1.jpg';
import imgSqar1 from '../../img/galleryImgSqar1.jpg';
import imgSqar2 from '../../img/galleryImgSqar2.jpg';
import imgVert1 from '../../img/galleryImgVert1.jpg';
import imgVert2 from '../../img/galleryImgVert2.jpg';
import imgVert3 from '../../img/galleryImgVert3.jpg';
import imgVert4 from '../../img/galleryImgVert4.jpg';
import imgVert5 from '../../img/galleryImgVert5.jpg';
import imgAdSqarFacial from '../../img/adSqarFacial.jpg';
import imgAdSqarFacial1 from '../../img/adSqarFacial1.jpg';
import imgAdSqarFacial2 from '../../img/adSqarFacial2.jpg';
import imgAdSqarFacial4 from '../../img/adSqarFacial4.jpg';
import imgAdSqarFacial5 from '../../img/adSqarFacial5.jpg';
import imgAdSqarFacial6 from '../../img/adSqarFacial6.jpg';
import imgAdSqarFacial7 from '../../img/adSqarFacial7.jpg';
import imgAdSqarFacial8 from '../../img/adSqarFacial8.jpg';
import imgAdSqarFacial9 from '../../img/adSqarFacial9.jpg';
import imgAdSqarFacial10 from '../../img/adSqarFacial10.jpg';
import imgAdSqarLashLift1 from '../../img/adSqarLashLift1.jpg';
import imgAdSqarLashLift2 from '../../img/adSqarLashLift2.jpg';
import imgAdVertFacial2 from '../../img/adVertFacial2.jpg';
import imgAdVertLashLift from '../../img/adVertLashLift.jpg';

const itemData = [
  {
    img: imgHorz1,
    title: 'Face Mask',
  },
  {
    img: imgVert1,
    title: 'Long Hair',
  },
  {
    img: imgVert2,
    title: 'Head Spa',
  },
  {
    img: imgSqar1,
    title: 'Lash Lift',
  },
  {
    img: imgSqar2,
    title: 'Facial Before and After',
  },
  {
    img: imgAdSqarFacial,
    title: 'Ad Facial Square',
  },
  {
    img: imgAdSqarFacial1,
    title: 'Ad Facial Square 1',
  },
  {
    img: imgAdSqarFacial2,
    title: 'Ad Facial Square 2',
  },
  {
    img: imgAdSqarFacial4,
    title: 'Ad Facial Square 4',
  },
  {
    img: imgAdSqarFacial5,
    title: 'Ad Facial Sqaure 5',
  },
  {
    img: imgAdSqarFacial6,
    title: 'Ad Facial Square 6',
  },
  {
    img: imgAdSqarFacial7,
    title: 'Ad Facial Square 7',
  },
  {
    img: imgAdSqarFacial8,
    title: 'Ad Facial Square 8',
  },
  {
    img: imgAdSqarFacial9,
    title: 'Ad Facial Square 9',
  },
  {
    img: imgAdSqarFacial10,
    title: 'Ad Facial Square 10',
  },
  {
    img: imgAdSqarLashLift1,
    title: 'Ad Lash Lift 1',
  },
  {
    img: imgAdSqarLashLift2,
    title: 'Ad Lash Lift 2',
  },
  {
    img: imgAdVertFacial2,
    title: 'Ad Facial Vert 2',
  },
  {
    img: imgAdVertLashLift,
    title: 'Ad Lash Lift Vert',
  },
  {
    img: imgVert3,
    title: 'Head Spa Comb 2',
  },
  {
    img: imgVert4,
    title: 'Head Spa Scalp Scrub',
  },
  {
    img: imgVert5,
    title: 'Head Spa Scalp Microscope',
  },
];

export default function Gallery() {
  return (
    <Views.CenterColumn title="Gallery">
      <Box sx={{ width: '100%' }}>
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem key={item.title}>
              <img
                src={item.img}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Views.CenterColumn>
  );
}
