import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import classNames from 'classnames';

import logo from '../../clarezaSkinLogo.jpg';
import { ViewButton } from '../Button';

const useStyles = makeStyles((theme) => ({
  navRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column wrap',
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  navItem: {
    width: '100%',
    display: 'flex',
    '& > p': {
      margin: theme.spacing(5),
    },
  },
  navButton: {
    color: 'black!important',
    textTransform: 'none!important',
    margin: '40px!important',
  },
  gridRoot: {
    margin: 0,
    width: 'unset',
  },
  logo: {
    width: 'auto',
    height: '250px',
    display: 'block',
    [theme.breakpoints.down('md')]: {
      height: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '250px',
    },
  },
}));

function NavButton({ children, ...props }) {
  const classes = useStyles();
  return (
    <ViewButton {...props} classes={{ root: classes.navButton }}>
      {children}
    </ViewButton>
  );
}

function NavItem({ children }) {
  const classes = useStyles();
  return (
    <div
      className={classes.navItem}
    >
      {children}
    </div>
  );
}

export default function NavBar() {
  const classes = useStyles();
  return (
    <div className={classes.navRoot}>
      <div className={classes.flexGrow} />
      <div>
        <NavItem align="right">
          <NavButton view={{ url: '/app/about' }}>
            <Typography>About Me</Typography>
          </NavButton>
          <NavButton view={{ url: '/app/contact' }}>
            <Typography>Contact Me</Typography>
          </NavButton>
        </NavItem>
      </div>
      <div>
        <img src={logo} alt="Clareza Skin" className={classes.logo} />
      </div>
      <div>
        <NavItem>
          <NavButton view={{ url: '/app/services' }}>
            <Typography>Shop/</Typography>
            <Typography>Services</Typography>
          </NavButton>
          <NavButton view={{ url: '/app/gallery' }}>
            <Typography>Gallery</Typography>
          </NavButton>
        </NavItem>
      </div>
      <div className={classes.flexGrow} />
    </div>
  );
}
