/* eslint react/jsx-no-target-blank: 0, react/no-unknown-property: 0, max-len: 0, react/self-closing-comp: 0, react/jsx-closing-tag-location: 0, jsx-a11y/media-has-caption: 0 */
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  /* Video element styling */
  videoGPT: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '250px', /* Adjust width as needed */
    zIndex: 1000, /* Adjust z-index to make sure it appears above other content */
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    /* Additional styling for the video container (optional) */
    '&:before': {
      content: '',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', /* Optional overlay color */
    },
  },
}));

export default function VideoOverlay({ src, type }) {
  const classes = useStyles();
  // const ref = React.useRef();

  return (
    <video className={classes.videoGPT} muted controls autoPlay>
      <source src={src} type={type} />
      Your browser does not support the video tag.
    </video>
  );
}
