import React from 'react';
import {
  Grow,
  Paper,
  // Grid,
  // CircularProgress,
  Typography,
  useMediaQuery,
  // useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Star, StarBorder, StarHalf } from '@mui/icons-material';

import { useAppContext } from '../../context/AppContext';
import useReviews from '../../utlities/useReviews';

function Header() {
  return (
    <div style={{ display: 'none' }}>
      <Typography sx={{ fontWeight: 'bold', fontSize: '1.5em' }}>
        Reviews
      </Typography>
    </div>
  );
}

function Layout({ children }) {
  return (
    <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
      <div style={{ flexGrow: 1 }}>&nbsp;</div>
      {children}
      <div style={{ flexGrow: 1 }}>&nbsp;</div>
    </div>
  );
}

function SkeletonReview() {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" width={210} sx={{ fontSize: '1rem' }} />

      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>
  );
}

function StarIcon({ rating }) {
  const color = 'gold';
  if (rating === 1) {
    return <Star sx={{ color }} />;
  }
  if (rating === 0.5) {
    return <StarHalf sx={{ color }} />;
  }
  return <StarBorder sx={{ color }} />;
}

const useStyles = makeStyles((theme) => ({
  reviewPaper: {
    width: '30em',
    backgroundColor: 'inherit',
    margin: '10px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  container: {
    width: '450px', // Set your desired width
    height: '175px', // Set your desired height
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 5, // Set the number of lines to show
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
  },
  paragraph: {
    overflow: 'hidden',
    position: 'relative',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 5, // Set the number of lines to show
  },
  ellipsis: {
    content: '""',
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: '0 10px', // Adjust padding as needed
    background: 'linear-gradient(to right, rgba(255, 255, 255, 0), white 50%)', // Fade effect
  },
}));

/**
 * @param {import('../../utlities/getReviewData').Review} props
 */
function Review(props) {
  const classes = useStyles();
  const [fadeIn, setFadeIn] = React.useState(false);
  const { author_name: author, rating, text, maxRating = 5, fullReview, relative_time_description: timeSince, life } = props;

  React.useEffect(() => {
    if (life < 800) return;
    setTimeout(() => {
      setFadeIn(true);
    }, 200);
    setTimeout(() => {
      setFadeIn(false);
    }, life - 500);
  }, [setFadeIn, life]);

  const full = Math.floor(rating);
  const stars = new Array(full).fill((true)).map(() => 1);
  if (full < rating && stars.length < maxRating) {
    // Use a partial rating
    stars.push(0.5);
  }
  while (stars.length < maxRating) {
    stars.push(0);
  }

  return (
    <Grow in={fadeIn}>
      <Paper className={classes.reviewPaper}>
        <Typography sx={{ fontWeight: 'bold', padding: '10px', paddingBottom: 0 }}>
          {author}
        </Typography>
        <div style={{ padding: '10px', paddingTop: 0, display: 'flex', alignItems: 'center' }}>
          {stars.map((r, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <StarIcon key={`${i}-star-${r}`} rating={r} />
          ))}
          <div style={{ display: 'inline-block', paddingLeft: '10px' }}>
            {timeSince}
          </div>
        </div>
        {!fullReview && (
          <div style={{ padding: '10px' }} className={classes.container}>
            <p className={classes.paragraph}>
              {text}
              {/* eslint-disable-next-line */}
              <span className={classes.ellipsis}></span>
            </p>
          </div>
        )}
        {fullReview && (
          <div style={{ padding: '10px' }}>
            {text}
          </div>
        )}
      </Paper>
    </Grow>
  );
}

export default function GoogleReviews({ debug = false, cyclePeriod = 5000, minRating = 1 }) {
  const { googlePlacesApiKey, googlePlaceId } = useAppContext();
  const { loading, error, reviews } = useReviews(googlePlacesApiKey, googlePlaceId, minRating);
  // const theme = useTheme();

  const [reviewStart, setReviewStart] = React.useState(0);

  const isSmall = useMediaQuery('(max-width: 600px)');
  // const reviewCount = 3;
  const reviewCount = isSmall ? 1 : 3;
  // reviewCount = 1;

  React.useEffect(() => {
    function updateListStart() {
      if (!Array.isArray(reviews) || reviews.length <= reviewCount) return;
      const nextStart = reviewStart + reviewCount;
      if (nextStart > reviews.length - 1) {
        setReviewStart(0);
      } else {
        setReviewStart(nextStart);
      }
    }
    const intId = setInterval(updateListStart, cyclePeriod);
    return () => {
      clearInterval(intId);
    };
  }, [reviewStart, setReviewStart, reviews, reviewCount, cyclePeriod]);

  if (loading || debug === 'loading') {
    return (
      <Layout>
        <Header />
        <SkeletonReview />
        {!isSmall && (
          <>
            <SkeletonReview />
            <SkeletonReview />
          </>
        )}
      </Layout>
    );
  }

  if (error) {
    console.error(error);
    console.error(`Error Loading Reviews\n${error.message}`);
    return null;
  }

  if (!Array.isArray(reviews) || reviews.length === 0) return null;

  const renderedReviews = reviews.slice(reviewStart, reviewStart + reviewCount);
  return (
    <Layout>
      <Header />
      {renderedReviews.map(R => (
        <Review key={R.text} {...R} life={cyclePeriod} />
      ))}
    </Layout>
  );
}
