import React from 'react';
import { Divider as MuiDivider, Typography } from '@mui/material';
// import { useTheme } from '@mui/material/styles';

import useMap from '../../utlities/useMap';
import Views from '../Views';
import Button from '../Button';
import { useAppContext } from '../../context/AppContext';

function Spacer({ size = 5 }) {
  return (
    <div style={{ margin: `${size}px` }}>&nbsp;</div>
  );
}
function Divider() {
  return (
    <MuiDivider sx={{ margin: '20px' }} />
  );
}

function Hours() {
  // const theme = useTheme();
  const { hours } = useAppContext();
  return (
    <div style={{ textAlign: 'center' }}>
      <Typography>
        Hours of Operation
      </Typography>
      {Object.keys(hours.dayHoursMap).map(dow => (
        <Typography key={dow}>{dow}: {hours.dayHoursMap[dow]}</Typography>
      ))}
    </div>
  );
}

export default function ContactMe() {
  const { mapProps, location } = useAppContext();
  const mapElementId = 'location-map';
  const { initMap } = useMap({
    ...mapProps,
    mapId: 'ClarezaSkin',
    containerId: mapElementId,
  });
  React.useEffect(() => {
    initMap();
  });

  return (
    <Views.CenterColumn>
      <div style={{ textAlign: 'center' }}>
        <Typography sx={{ fontSize: '60px', lineHeight: 'unset' }}>
          CLAREZA SKIN
        </Typography>
        <Spacer />
        <Typography>
          {location.address}
          {location.suite && (
            `, ${location.suite}`
          )}
        </Typography>
        <Typography>
          {location.city} {location.state}, {location.zip}
        </Typography>
        <Spacer />
        <Typography>
          {location.phone}
        </Typography>
      </div>
      <Divider />
      <Hours />
      <Spacer />
      <div style={{ textAlign: 'center' }}>
        <Button
          sharp
          variant="contained"
        >
          Contact Me
        </Button>
      </div>
      <Divider />
      <div id={mapElementId} style={{ width: '100%', height: '500px' }}>&nbsp;</div>
      <Spacer size={10} />
    </Views.CenterColumn>
  );
}
