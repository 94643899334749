import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  img: {
    width: '100%',
    display: 'block',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function ImageUnderlay({ children, src, alt }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={src} alt={alt} className={classes.img} />
      <div className={classes.overlay}>
        <div>
          {children}
        </div>
      </div>
    </div>
  );
}
