/**
 * @typedef {Object}      Point
 * @property {Number}     lat - Latitude
 * @property {Number}     lng - Longitude
 */

/**
 * @typedef {Object}          UseMapOptions
 * @property {Point}          center
 * @property {String}         mapId
 * @property {Boolean|Point}  marker - true = Use center for Marker, Point = Use another point for Marker
 * @property {String}         markerTitle
 */

/**
 * @typedef {Object}      UseMapResult
 * @property {Function}   initMap - Function to be called in useEffect
 */

/**
 * @param {UseMapOptions} options
 * @returns {UseMapResult}
 */
export default function useMap(options = {}) {
  const { center, marker, markerTitle, mapId, containerId } = options;
  if (!mapId) console.error('useMap: mapId property is required but is not defined.');
  let map;
  async function initMap() {
    try {
      // eslint-disable-next-line no-undef
      const { Map: GMap } = await google.maps.importLibrary('maps');
      // eslint-disable-next-line no-undef
      const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
      map = new GMap(document.getElementById(containerId), {
        center,
        zoom: 16,
        mapId,
      });
      if (marker && center) {
        const markerPostion = marker === true ? center : marker;
        // eslint-disable-next-line no-unused-vars
        const gmarker = new AdvancedMarkerElement({
          map,
          position: markerPostion,
          title: markerTitle,
        });
      }
    } catch (e) {
      console.error('initMap Error');
      console.error(e);
    }
  }
  return { initMap };
}
