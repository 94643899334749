import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useAppContext } from '../../context/AppContext';

const sizes = {
  sidebar: { xs: 1, lg: 2 },
  center: { xs: 10, lg: 8 },
};
function PCenterColumn({ left, right, children }) {
  return (
    <Grid container>
      <Grid item {...sizes.sidebar}>
        {left}
        {!left && (
          <React.Fragment>
            &nbsp;
          </React.Fragment>
        )}
      </Grid>
      <Grid item {...sizes.center}>
        {children}
      </Grid>
      <Grid item {...sizes.sidebar}>
        {right}
        {!right && (
          <React.Fragment>
            &nbsp;
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}

export default function CenterColumn({ title, left, right, children }) {
  const { setHomeView } = useAppContext();
  return (
    <React.Fragment>
      <PCenterColumn>
        <div style={{ display: 'flex', justifyContent: title ? 'space-between' : 'end' }}>
          {title && (
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold', marginTop: '20px' }}>
              {title}
            </Typography>
          )}
          <IconButton onClick={setHomeView}>
            <CloseIcon />
          </IconButton>
        </div>
      </PCenterColumn>
      <PCenterColumn left={left} right={right}>
        {children}
      </PCenterColumn>
    </React.Fragment>
  );
}
