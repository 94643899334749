import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useAppContext } from '../../context/AppContext';
import Button, { ViewButton } from '../Button';
import useMap from '../../utlities/useMap';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(5),
    '& > div': {
      textAlign: 'center',
      width: '33%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  map: {
    width: '200px',
    height: '200px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

function FBtn({ children, ...props }) {
  const BtnComp = props.view ? ViewButton : Button;
  return (
    <BtnComp
      {...props}
      dark
      color="primary"
    >
      {children}
    </BtnComp>
  );
}

export default function Footer({ hideMap }) {
  const classes = useStyles();
  const { mapProps, location, hours, onBookClick } = useAppContext();
  const mapElementId = 'location-map-footer';
  const { initMap } = useMap({
    ...mapProps,
    mapId: 'ClarezaSkinFooter',
    containerId: mapElementId,
  });
  React.useEffect(() => {
    initMap();
  });
  const onSupplierClick = () => {
    const el = document.getElementById('supplier');
    el.click();
  };
  return (
    <div className={classes.footer}>
      <div>
        <div>
          <FBtn view={{ url: '/app/about' }}>
            About Me
          </FBtn>
        </div>
        <div>
          <FBtn view={{ url: '/app/contact' }}>
            Contact Me
          </FBtn>
        </div>
        <div>
          <FBtn view={{ url: '/app/services' }}>
            Services
          </FBtn>
        </div>
        <div>
          <FBtn onClick={onBookClick}>
            Book Now
          </FBtn>
        </div>
        <div>
          <a style={{ display: 'none' }} id="supplier" href="https://www.haleandhush.com/" target="_blank" rel="noreferrer">
            Hale & Hush
          </a>
          <FBtn
            onClick={onSupplierClick}
          >
            Hale & Hush
          </FBtn>
        </div>
      </div>
      <div
        id={mapElementId}
        className={classes.map}
        style={{ visibility: hideMap ? 'hidden' : '' }}
      >
        &nbsp;
      </div>
      <div style={{ color: 'white' }}>
        <Typography>
          {location.address}
        </Typography>
        {location.suite && (
          <Typography>
            {location.suite}
          </Typography>
        )}
        <Typography>
          {location.city}
        </Typography>
        <Typography>
          {location.state}, {location.zip}
        </Typography>
        <div style={{ height: '20px' }}>&nbsp;</div>
        {Object.keys(hours.dayHoursMap).map(dow => (
          <Typography key={dow}>{dow}: {hours.dayHoursMap[dow]}</Typography>
        ))}
      </div>
    </div>
  );
}
