import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Views from '../Views';

import HeadshotImg from '../../Headshot.jpg';

const useStyles = makeStyles((theme) => ({
  aboutMeImg: {
    width: '200px',
    height: 'auto',
    margin: '0 10px 10px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function AboutMe() {
  const classes = useStyles();
  return (
    <Views.CenterColumn title="About Me">
      <Typography>
        <p>
          <img src={HeadshotImg} alt="About Me" align="left" className={classes.aboutMeImg} />
          Hi! My name is Sarah Fannin.  I was born and raised in Bradenton. I live on the West side, and have my whole life.
          I cannot wait to bring this much needed facial spa to this side of town,
          which holds a special place in my heart.
        </p>
        <p>
          Ever since I can remember, I&apos;ve had a deep passion for skin care.
          It has been my personal sanctuary, a way for me to unwind and prioritize self-care.
          After gaining experience in the field, working in dermatology and medicine for a couple of years,
          I took on the fulfilling role of a stay-at-home mom to my two beautiful daughters.
        </p>
        <p>
          Now, the time has come for me to pursue my true passion and combine my love for skin care with
          my desire to help others. I believe in creating a harmonious blend of clinical expertise and
          holistic treatments to offer the best possible experience for my clients.
        </p>
        <p>
          My approach is rooted in the belief that healthy skin is a reflection of overall well-being.
          I strive to provide personalized treatments that not only address specific concerns
          but also promote a sense of relaxation and rejuvenation.
          By combining my knowledge and expertise with the power of natural and holistic practices,
          I aim to achieve remarkable results.
        </p>
        <p>
          I am thrilled to be on this journey and share my passion with you.
          Together, let&apos;s embark on a transformative skin care experience that n urtures
          not just your outer beauty but also your inner radiance.
        </p>
      </Typography>
    </Views.CenterColumn>
  );
}
