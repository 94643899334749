/**
 * @param {String} apiKey
 * @param {String} placeId
 * @returns {Promise<PlaceDetails>}
 */
const getReviewData = (apiKey, placeId) => new Promise((resolve, reject) => {
  fetch(`https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${apiKey}`)
    .then(response => response.json())
    .then(data => {
      if (!data || !data.result) {
        console.error(data);
        reject(new Error('Google Place Details response is missing "result" property.'));
        return;
      }
      // const reviews = data.result.reviews;
      // // Iterate through the reviews and display them on your website
      // reviews.forEach(review => {
      //   // Display review data as needed
      //   console.log(`${review.author_name} rated ${review.rating}: ${review.text}`);
      // });
      resolve(data);
    })
    .catch(error => {
      console.error('getReviewData caught ERROR');
      reject(error);
    });
});
export default getReviewData;
