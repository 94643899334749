import React from 'react';

import getReviewData from './getReviewData';

/**
 * @typedef {Object}                                        ReviewsState
 * @property {Array<import("./getReviewData").Review>}      reviews
 * @property {Boolean}                                      loading
 * @property {Error}                                        error
 */

/**
 * @param {String} apiKey
 * @param {String} placeId
 * @param {Number} minRating
 * @returns {ReviewsState}
 */

export default function useReviews(apiKey, placeId, minRating) {
  const [state, setState] = React.useState({ loading: true });

  React.useEffect(() => {
    async function getData() {
      try {
        const placeDetails = await getReviewData(apiKey, placeId);
        const reviews = placeDetails.reviews.filter(r => {
          if (r.rating < minRating) return false;
          return true;
        });
        setState({ loading: false, reviews });
      } catch (e) {
        setState({ loading: false, error: e });
      }
    }
    getData();
  }, [apiKey, placeId, minRating]);

  return state;
}
