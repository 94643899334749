import React from 'react';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// import page1 from './Facial_Menu_Page1.png';
// import page2 from './Facial_Menu_Page2.png';
import scalpOilPic from '../../ScalpOil_1oz.jpg';
import giftCardPic from '../../giftCard.jpg';
import Views from '../Views';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      fontWeight: 'bold',
      fontSize: '18px',
    },
  },
  content: {
    paddingLeft: theme.spacing(5),
  },
  productPic: {
    width: '250px',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

function Product({ title, href, src }) {
  const classes = useStyles();
  return (
    <div>
      <a href={href} rel="noreferrer" target="_blank">
        <img src={src} alt={title} className={classes.productPic} />
      </a>
      <Typography sx={{ textAlign: 'center' }}>{title}</Typography>
    </div>
  );
}

function Service({ title, price, time, description }) {
  const classes = useStyles();
  return (
    <Paper classes={{ root: classes.paperRoot }} elevation={6}>
      <div className={classes.header}>
        <Typography>{title}</Typography>
        <Typography>${price}</Typography>
      </div>
      <div className={classes.content}>
        <Typography>
          {description}
        </Typography>
        {time && (
          <Typography sx={{ fontWeight: 'bold' }}>
            {time} Minutes
          </Typography>
        )}
      </div>
    </Paper>
  );
}

const services = [
  {
    title: '90 Minute Customized Facial',
    price: 140,
    description: `
    The utmost in luxury, relaxation and glowing skin.
     An exfoliating deep cleanse, enzyme peel on the face and neck,
     followed by extractions, high frequency, LED light treatment,
     relaxing face massage, nano infusion and a mask to refresh and re-balance.
     Will customize your treatment to address specific skin conditions.
    `,
  },
  {
    title: 'Scalp Treatment (60 Minutes)',
    price: 100,
    // time: 60,
    description: `
    This Deeply detoxifying, cleansing, invigorating scalp treatment starts with a look
     with a microscope at your scalp and hair follicles!
    Followed with a lightly exfoliating refreshing spray on the scalp,
     combed in deeply to remove any flakes or dry skin. Next, a hot oil application
     with massage and combing through the hair very precisely to spread oil from roots throughout hair.
    Next, An exfoliating scrub is applied throughout the hair and massaged and brushed in.
    Followed by a washing of an invigorating scalp shampoo and conditioner, hair mask, etc.
    Lastly, a light drying of your hair.
    This Treatment designed to prevent thinning hair and hair loss.
    Deep exfoliation will unclog follicles for promotion of healthy hair growth.
    Treatment designed to control overactive oil production, eliminate odor, and revive hair.
    Treatment also designed to address the needs of a dry and flaky scalp to relieve itchiness,
     dryness and irritation. Works to prevent thinning and wrinkles, as well.
     Scalp care is very important because it provides the necessary environment for growing healthy,
      strong, beautiful hair. I recommend a head spa session every 30 to 45 days.
    This concept makes sense when you consider the VIP treatment we give to skin
     elsewhere on our bodies, where we’re not even trying to cultivate luscious locks.
    It has been a hugely popular treatment called "head spas" in Asia
    `,
  },
  {
    title: 'Scalp Treatment (90 Minutes)',
    price: 140,
    description: `
    This Deeply detoxifying, cleansing, invigorating scalp treatment starts with a look with a microscope
    at your scalp and hair follicles! Followed with a lightly exfoliating refreshing spray on the scalp,
    combed in deeply to remove any flakes or dry skin and then massaged into the scalp. Next, a hot oil
    application with massage and combing through the hair very precisely to spread oil from roots throughout hair.
    Next, An exfoliating scrub is brushed through hair and then massaged into scalp. Followed by an invigorating double
    cleanse of hair with scalp therapy shampoo and conditioner, next a moisturizing mask is left on the hair
    to penetrate and condition, then rinsed out. Next, a light drying of your hair with finishing products
    and high frequency scalp treatment comb. Finally, we look again with microscope to see difference after treatment
    vs. Before. This Treatment designed to relax clients while helping to prevent thinning hair and hair loss.
    Deep exfoliation will unclog follicles for promotion of healthy hair growth. Treatment designed to control
    overactive oil production, eliminate odor, and revive hair. Treatment also designed to address the needs of a
    dry and flaky scalp to relieve itchiness, dryness and irritation. Works to prevent thinning and wrinkles,
    as well. Scalp care is very important because it provides the necessary environment for growing healthy, strong,
    beautiful hair. I recommend a head spa session every 30 to 45 days. This concept makes sense when you consider the
    VIP treatment we give to skin elsewhere on our bodies, where we’re not even trying to cultivate luscious locks.
    It has been a hugely popular treatment called "head spas" in Asia.
    `,
  },
  {
    title: 'Carboxy Facial',
    price: 130,
    time: 60,
    description: `
    Carboxy Therapy is a Korean oxygenating treatment that increases circulation, improves skin texture,
    and has a powerful brightening effect.\n
    This two-part treatment includes a carbon dioxide gel and treatment mask.  When used together
    they create what is known as the Bohr effect; a sudden release of oxygen within the skin's
    tissue to compensate for oxygen loss.
    Oxygen is involved in numerous biological processes including cell proliferation, angiogenesis,
    and protein synthesis.  It is key to having healthy skin.

    This is appropriate for those with acne, because acne bacteria is anaerobic (cannot survive in an
    oxygenated environment), and for aging or dehydrated skin because of the delivery of nutrients and
    hydration. Basically, its great for almost every issue.
    Carboxy replaces the exfoliation step in custom facial. 
    Many notice a post glow upon removing the Carboxy mask,and this is completely normal, what you're
    seeing is the increase in micro-circulation. 
    `,
  },
  {
    title: 'VTox Facial',
    price: 130,
    time: 60,
    description: `
    Dubbed a facelift in a bottle, KrX's V-Tox is a revolutionary formula which transforms the skin, providing
    an immediate visible lift in about an hour.
    This peptide-packed formula increases microcirculation which aids in the delivery of vital nutrients and supports
    natural collagen and elastin production.
    This is a non-invasive, and pain free treatment which offers a safe and effective alternative to surgical
    facelifts.  I recommend the Youthplex Line for home care, and a series of three treatments, spaced out two
    weeks apart for lasting results.  The term "V-lift" refers to a popular facial contouring trend in Korean
    beauty and skincare.  It is designed to create a more slim and defined V-shaped face, which is considered
    youthful.
    The V-Tox by KrX is an incredible rejuvenating treatment that lifts, tightens and contours the face all while
    delivering important peptides to help stimulate collagen adn elastin.  Your clients will leave feeling like
    they've just had a mini face-lift and it's completely painless!
    The benefits of the V-Tox include increased micro-circulation (as the mask dries) which increases the delivery
    of nutrients, and also collagen stimulation with the abundance of peptides found in the formula.
    This "Facelift in a Bottle" is added to our customized facial.
    `,
  },
  {
    title: 'Ultimate Queen of the Universe Facial',
    price: 220,
    time: 120,
    description: 'Dermaplaning, followed by Carboxy Facial + VTox Facial',
  },
  {
    title: 'Green Sea Peel',
    price: 140,
    time: 60,
    description: `
    The Green Sea Peel is a resurfacing treatment that is natural, safe for all fitzpatricks, and safe during
    pregnancy.  Spicules are quickly becoming the go-to alternative for microneedling.  This peel works by driving
    spicules into the skin, which stimulates collagen, exfoliates, and pulls pigment, all while delivering a
    polypharmacy of vitamins and nutrients into the skin which helps improve numerous skin concerns.
    The Green Sea Peel comes with an activator which is formulated with Hyaluronic Acid, Glucan, Allantonin,
    and a host of growth factors.
    NOTE: Requires consultation prior to treatment.
    The Green Sea Peel requires preparation 2 weeks prior that includes a lighter spicule at-home treatment,
    and no other exfoliation (including acids and retinol).
    `,
  },
  {
    title: '60 Minute Customized Facial',
    price: 100,
    description: `
    This treatment includes an exfoliating deep cleanse, an enzyme peel on the face and neck,
     followed by extractions, high frequency, relaxing massage and a mask to refresh and re-balance.
     All facials customized.
    `,
  },
  {
    title: '30 Minute Express Facial',
    price: 65,
    description: `
    This express facial will leave your skin feeling fresh and renewed.
     Deep cleanse, mini-massage and mask will improve skin health and clarity.
    `,
  },
  {
    title: 'Dermaplaning Mini Facial',
    price: 75,
    time: 45,
    description: `
    Double cleanse, dermaplaning (scalpel exfoliation of face), sheet mask with cold globes, moisturizer, SPF.
    `,
  },
  {
    title: 'Teen Facial',
    price: 75,
    time: 45,
    description: `
    For our younger clients. Deep cleanse, extractions, high frequency and mask.
     Detoxifies acne-prone skin and purifies complexion.
    `,
  },
  { title: 'Specialty Treatments', heading: true },
  {
    title: 'Ultra Soothing/Acne Korean Facial',
    price: 150,
    time: 90,
    description: `
    Is your skin red and sensitive? Having angry breakouts? Let's fix that.
     The Omelon Omega Green is a two-part healing treatment made to improve the symptoms of
     rosacea and eczema and accelerate the healing of acne.
    Treatment consists of one syringe of chlorophylln copper complex, a packet of allantoin,
     and a PDT sheet mask.
    This treatment uses nanoinfusion of treatment and 2 different LED light treatments
     during the procedure. (All usual facial steps are included: double cleanse,
     massage, finishing products.)
    `,
  },
  { title: 'Add-Ons', heading: true },
  {
    title: 'Buccal Massage',
    price: 65,
    time: 30,
    description: `
    Buccal face massage is is a technique that involves massaging the face from the inside of the mouth,
     stretching the cheeks and accessing deeper muscles.
    It sculpts the face and relieves tension in the jaw from daily stressors or TMJ pain.
    (Signature massage included)
    `,
  },
  {
    title: 'Hydropeptide Jelly Mask',
    price: 15,
    time: 15,
    description: `
    This peel-off mask balances skin tone and restores hydration.
     Aloe vera and seaweed cool and soothe skin while algae extract binds water to your complexion,
     creating a visible plumping effect.
    I use cold globes to massage this jelly mask in for a cooling refreshing relaxing experience.
    Key Features: Peptides firm skin and fade the look of wrinkles. Brightens skin tone with niacinamide.
    Reduces the appearance of pores Can be added to any facial service (Facials, Dermaplaning)
    `,
  },
  {
    title: 'Dermaplaning Add-on to Facial',
    price: 50,
    time: 30,
    description: `
    Add dermaplane (scalpel exfoliation) to any facial you choose for extra penetration
     of products and a glowing and smooth texture.
    `,
  },
  // {
  //   title: 'Carboxy Therapy add-on (Korean Oxygen Treatment)',
  //   price: 15,
  //   time: 15,
  //   description: `
  //   Carboxy is an oxygenating treatment that is beneficial for most skin types.
  //   Here's how it works: This two part treatment includes a carbon dioxide gel and treatment mask.
  //   When used together they create what is known as the Bohr effect.
  //   The Bohr effect happens when the skin is deprived of oxygen, which triggers a reaction
  //    that causes a sudden release of oxygen within the skin's tissue to compensate.
  //   Please note: A sizzling sound upon application is to expected, as well as a tingling sensation.
  //   An increase in oxygen boosts collagen production, accelerates healing,
  //    and has a deeply hydrating effect Beneficial for acne-prone skin.
  //   Propionibacterium acnes is a gram-positive and prefers anaerobic growth conditions.
  //   Oxygenating the skin creates an unfavorable environment.
  //   There will be a noticeable difference in skin's hydration and texture right away.
  //   `,
  // },
  {
    title: 'Korean Chemical Peel (Acne or Pigmentation)',
    price: 20,
    time: 15,
    description: `
    This is a korean chemical peel with no downtime.
    This can be added to any facial service or dermaplaning for added brightening and exfoliation.
    It can be configured for either Acne treatment or Pigmentation treatment.
    I will choose a Korean peel based on your skins needs.
    `,
  },
];

const products = [
  {
    href: 'https://squareup.com/gift/MLJTJR22FQ949/order',
    title: 'Gift Card',
    src: giftCardPic,
  },
  {
    href: 'https://clarezaskin.square.site/product/prewash-scalp-oil/24?cs=true&cst=popular',
    title: 'Scalp Oil',
    src: scalpOilPic,
  },
];

export default function Services() {
  // const imgStyle = {
  //   width: '100%',
  //   height: 'auto',
  // };
  const classes = useStyles();
  return (
    <>
      <Views.CenterColumn title="Shop">
        <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
          {products.map(p => (
            <Product {...p} key={p.href} />
          ))}
        </div>
      </Views.CenterColumn>
      <Views.CenterColumn title="Services">
        <div className={classes.listRoot}>
          {services.map(s => {
            if (s.heading) {
              return (
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>{s.title}</Typography>
              );
            }
            return (
              <Service key={s.title} {...s} />
            );
          })}
        </div>
      </Views.CenterColumn>
    </>
  );
}
