/**
 * @typedef {Object}            AddressComponent
 * @property {String}           long_name
 * @property {String}           short_name
 * @property {Array<String>}    types
 */

/**
 * @typedef {Object}            Photo
 * @property {Number}           height
 * @property {Number}           width
 * @property {Array<String>}    html_attributions
 * @property {String}           photo_reference
 */

/**
 * @typedef {Object}            Review
 * @property {String}           author_name
 * @property {String}           author_url
 * @property {String}           language
 * @property {String}           original_language
 * @property {String}           profile_photo_url
 * @property {Number}           rating
 * @property {String}           relative_time_description
 * @property {String}           text - Review content
 * @property {Number}           time - Review timestamp
 * @property {Boolean}          translated
 */

/**
 * @typedef {Object}                        PlaceDetails
 * @property {Array<AddressComponent>}      address_components
 * @property {String}                       adr_address
 * @property {String}                       business_status
 * @property {Array<Photo>}                 photos
 * @property {String}                       place_id
 * @property {String}                       plus_code
 * @property {String}                       rating
 * @property {Array<Review>}                reviews
 */

import getData from './getReviewData.prod';

/**
 * @param {String} apiKey
 * @param {String} placeId
 * @returns {Promise<PlaceDetails>}
 */
export default function getReviewData(apiKey, placeId) {
  return getData(apiKey, placeId);
}
